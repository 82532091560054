




























































































































import { Component, Vue, Watch } from 'vue-property-decorator'

import {
  getAddressList,
  getZoneList,
  getConfirmOrderInfo,
  addAddress,
  updateAddress,
  deleteAddress,
  submitOrder
} from '@/pages/videoGame/api'
import { getAppLanguage } from '@/common/utils'
@Component
export default class extends Vue {
  language = getAppLanguage()
  addressList: any = []
  goodsId = this.$route.query.goodsId as string
  chosenAddressId = ''
  tips = ''
  showAddressPop = false
  showAddAddressPop = false
  get disabledCommit() {
    if (this.dataInfo.goodsType == 1) {
      return !this.dataInfo.addressId
    } else {
      return this.userPhone.length != 11
    }
  }
  @Watch('showAddAddressPop')
  onShowAddAddressPopChange(val: boolean) {
    if (!val) {
      this.saveAddressType = 'add'
      this.editAddressId = ''
      this.editAddressInfo = {
        addressDetail: '',
        name: '',
        tel: '',
        areaCode: ''
      }
    }
  }
  editAddressId = ''
  areaList: any = {
    province_list: {},
    city_list: {},
    county_list: {}
  }
  saveAddressType = 'add'
  dataInfo = {
    addressId: '',
    userPhone: '',
    userName: '',
    address: '',
    goodsId: '',
    goodsName: '',
    goodsPic: '',
    useIntegral: '',
    exchangeStatus: '',
    verifyTips: '',
    ugVerifyTips: '',
    addressDetail: '',
    goodsType: 1
  }
  userPhone = ''
  editAddressInfo = {
    addressDetail: '',
    name: '',
    tel: '',
    areaCode: ''
  }
  showDeletePop = false
  commitLoading = false
  async getDataInfo() {
    const { data } = await getConfirmOrderInfo({ goodsId: this.goodsId })
    this.dataInfo = data
  }
  async getAddress() {
    const { data } = await getAddressList()
    this.addressList = data
  }
  get defaultAddress() {
    return this.addressList.find((item: any) => item.defalt == 1) || null
  }
  get vantAddressList() {
    return this.addressList.map((item: any) => {
      return {
        ...item,

        name: item.userName,
        tel: item.userPhone,
        address: item.address,
        isDefault: item.defalt == 1
      }
    })
  }
  handlClickAddress() {
    if (!this.dataInfo.addressId) {
      this.showAddAddressPop = true
    } else {
      this.showAddressPop = true
    }
  }
  async getArea() {
    const { data } = await getZoneList()
    const province_list: any = {}
    const city_list: any = {}
    const county_list: any = {}
    for (let i = 0; i < data.length; i++) {
      const province = data[i]
      province_list[province.zoneCode] = province.zoneName
      for (let j = 0; j < province.childZones.length; j++) {
        const city = province.childZones[j]
        city_list[city.zoneCode] = city.zoneName
        if (!city.childZones) continue
        for (let k = 0; k < city.childZones.length; k++) {
          const county = city.childZones[k]
          county_list[county.zoneCode] = county.zoneName
        }
      }
    }

    this.areaList = {
      province_list,
      city_list,
      county_list
    }
    console.log('areaList', this.areaList)
  }
  openAddAddress() {
    this.editAddressId = ''
    this.saveAddressType = 'add'
    this.showAddAddressPop = true
  }
  openEditAddress(item: any) {
    this.editAddressId = item.id
    this.editAddressInfo = {
      addressDetail: item.addressDetail,
      name: item.userName,
      tel: item.userPhone,
      areaCode: item.zoneCode
    }
    this.saveAddressType = 'edit'
    this.showAddAddressPop = true
  }
  onSelectAddress(item: any) {
    this.dataInfo.addressId = item.id
    this.dataInfo.address = item.address
    this.dataInfo.userName = item.userName
    this.dataInfo.userPhone = item.userPhone
    this.showAddressPop = false
  }
  onSaveAddress(content: any) {
    console.log('onSaveAddress', content)
    if (this.saveAddressType == 'add') {
      this.handlAddAddress(content)
    } else {
      this.handlEditAddress(content)
    }
  }
  async handlAddAddress(content: any) {
    const params = {
      addressDetail: content.addressDetail,
      userName: content.name,
      userPhone: content.tel,
      zoneCode: content.areaCode
    }
    const { data } = await addAddress(params)
    this.getAddress()
    this.getDataInfo()
    this.showAddAddressPop = false
  }
  async handlEditAddress(content: any) {
    const params = {
      addressDetail: content.addressDetail,
      userName: content.name,
      userPhone: content.tel,
      zoneCode: content.areaCode,
      id: this.editAddressId
    }
    const { data } = await updateAddress(params)
    this.getAddress()
    this.getDataInfo()
    this.showAddAddressPop = false
    this.editAddressInfo = {
      addressDetail: '',
      name: '',
      tel: '',
      areaCode: ''
    }
  }
  async onDeleteAddress() {
    //this.showDeletePop = true
    this.handlDeleteAddress()
  }
  async handlDeleteAddress() {
    const params = {
      id: this.editAddressId
    }
    await deleteAddress(params)
    this.$toast(this.$t('删除成功'))
    this.getAddress()
    this.getDataInfo()
    this.showAddAddressPop = false
  }

  async handlCommit() {
    if (this.dataInfo.goodsType == 1 && !this.dataInfo.addressId) {
      this.$toast(this.$t('请选择收货地址'))
      return
    }
    if (this.dataInfo.goodsType == 2) {
      if (!this.userPhone) {
        this.$toast(this.$t('请输入手机号'))
        return
      }
      if (this.userPhone.length != 11) {
        this.$toast(this.$t('请输入正确的手机号'))
        return
      }
    }
    if (this.commitLoading) return
    this.commitLoading = true
    const params: any = {
      goodsId: this.dataInfo.goodsId,
      goodsName: this.dataInfo.goodsName,
      goodsPic: this.dataInfo.goodsPic,
      useIntegral: this.dataInfo.useIntegral
    }
    if (this.dataInfo.goodsType == 1) {
      params.addressId = this.dataInfo.addressId
    } else {
      params.userPhone = this.userPhone
    }
    try {
      const { data } = await submitOrder(params)
      this.$router.replace({
        path: '/exchangeResult',
        query: {
          result: data.result
        }
      })
    } finally {
      this.commitLoading = false
    }
  }
  created() {
    this.getDataInfo()
    this.getArea()
    this.getAddress()
  }
}
